// Import Custom SB Admin 2 Variables (Overrides Default Bootstrap Variables)
@import "variables.scss";

// Import Bootstrap
@import "../vendor/bootstrap/scss/bootstrap.scss";

// Import FontAwesome
@import "../vendor/fontawesome-free/scss/fontawesome";
@import "../vendor/fontawesome-free/scss/solid";
@import "../vendor/fontawesome-free/scss/regular";

// Import Custom SB Admin 2 Mixins and Components
@import "mixins.scss";
@import "global.scss";
@import "utilities.scss";
@import "heading.scss";

// Custom Components
@import "dropdowns.scss";
@import "navs.scss";
@import "buttons.scss";
@import "cards.scss";
@import "charts.scss";
@import "login.scss";
@import "error.scss";
@import "footer.scss";
