button {
  &.time-option {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100px;
    border: 1px solid;

    i {
      display: block;
      margin-bottom: 12px;
    }
  }
}