.modal-body {
  .DayPicker {
    display: block;
    margin: auto;
  }
}

.sub-tasks {
  .sub-task {
    padding-bottom: 10px;
    .btn {
      padding: 0 5px;
    }
  }

  .input-group {
    background: #f8f8f8;
    border-bottom: 1px solid #ddd;
  }

  input, .input-group-prepend, .input-group-text {
    background: none;
    border: 0px;
  }
}

.task-files {
  .file {
    background: #f8f8f8;
    border-bottom: 1px solid #ddd;
    padding: 6px 15px;
    margin-bottom: 10px;

    .delete-button {
      float: right;
      position: relative;
      top: -6px;
      right: -11px;
    }
  }
}

textarea {
  &.form-control {
    min-height: 100px;
  }
}

