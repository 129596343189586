$tablet-width: 768px;
$desktop-width: 1024px;

.task-list {
  list-style: none;
  padding: 0;

  .task-date-cat {
    margin-top: 30px;
  }
}

#add-button-mobile {
  position: fixed;
  right: 14px;
  bottom: 50px;
  padding-top: 8px;
}

div[data-testid="list-wrapper"] {
  background: transparent;
}

.task-item {
  list-style: none;
  background: white;
  line-height: 40px;
  width: 100%;
  padding: 30px 30px 30px 30px;
  border-bottom: 1px solid #e3e6f0;

  cursor: pointer;

  &.draggable {
    position: relative;
    padding-left: 50px;
  }

  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    width: 100%;
  }

  .indicators {
    position: absolute;
    bottom: 5px;

    i {
      margin-right: 5px;
    }
  }

  .task-handle {
    padding: 10px;
    top: 50%;
    left: 10px;
    display: block;
    transform: translateY(-50%);
    position: absolute;
    cursor: move;

    .icon {
      width: 18px;
      height: 11px;
      opacity: .25;
      background: linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
    }
  }

  .task-buttons {
    float: right;
    opacity: 0;
    top: 30px;
    right: 30px;
    position: absolute;
  }

  &:hover {
    .task-buttons {
      opacity: 1;
    }
  }
}

.task-swipe-action {
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
  width: 100%;
  padding-top: 36px;
  color: white;

  &.task-swipe-close,
  &.task-swipe-now {
    background: #1aa747;
    text-align: left;
  }

  &.task-swipe-reopen,
  &.task-swipe-plan {
    background: #2653d4;
    text-align: right;
  }
}

@media (max-width: $tablet-width - 1) {
  .task-buttons {
    display: none;
  }
}